import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

import ModuleLayout from "../components/ModuleLayout";
import SuccessNext from "../components/Success/SuccessNext";

import Seo from "../components/General/Seo";
import useSiteMetadata from "../components/SiteMetadata";

const SuccessTemplate = ({ data, pageContext }) => {
  const { frontmatter, fields } = data.markdownRemark;
  const { meta, social } = useSiteMetadata();

  return (
    <Layout
      backgroundColour={frontmatter.backgroundColour}
      darkMenu={frontmatter.darkMenu}
    >
      <Seo
        title={
          frontmatter.title +
          meta.sitePageTitleSep +
          meta.successName +
          meta.sitePageTitleSep +
          meta.siteTitle
        }
        slug={meta.siteUrl + fields.slug}
        description={
          frontmatter.metaDescription
            ? frontmatter.metaDescription
            : frontmatter.excerpt
            ? frontmatter.excerpt
            : meta.successDescription
        }
        image={
          meta.siteUrl +
          (frontmatter.metaImage
            ? frontmatter.metaImage.publicURL
            : frontmatter.featuredimage && frontmatter.featuredimage.publicURL
            ? frontmatter.featuredimage.publicURL
            : meta.siteImage)
        }
        twitterTitle={frontmatter.title}
        facebookTitle={frontmatter.title}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
      />
      <ModuleLayout
        slug={frontmatter.path}
        blocks={frontmatter.content_blocks}
      />
      {data.allMarkdownRemark.edges[0] && (
        <SuccessNext posts={data.allMarkdownRemark.edges} />
      )}
    </Layout>
  );
};

SuccessTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default SuccessTemplate;

export const pageQuery = graphql`
  query SuccessTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        path

        title
        heading
        subheading

        darkMenu
        backgroundColour


        featuredimage {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
        }

        content_blocks {
          type

          headerType
          title
          subheading
          intro

          header0
          header1
          header2

          cta_1_link
          cta_1_text
          cta_1_button
          cta_2_link
          cta_2_text
          cta_3_link
          cta_3_text

          body
          content

          background
          align
          full_width

          image {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
          image_leftright

          faqs {
            question
            answer
          }

          statsTitle
          statsIntro
          stats {
            stat
            subtitle_1
            subtitle_2
          }

          testimonialFrom
          testimonialContent
          testimonialImage {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }

          benefits {
            title
            text
          }
          benefit_intro
          benefit_title
          hidePadding
          columns
          cards {
            title
            intro
            subtitle
            content
            color
            link
            logo {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }

            card_monthly_price
            card_yearly_price
            card_multiplier
            card_features
          }

          logo_title
          logos {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }

          thumbnail
          full_width
          video
          caption
          controls
          autoplay
          
        }
      }
    }

    allMarkdownRemark(
      limit: 3
      filter: { id: { nin: [$id] }, fields: { slug: { regex: "//success//" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date
            # date(formatString: "MMMM DD, YYYY")
            title
            excerpt
            featuredimage {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
            # tags
          }
        }
      }
    }
  }
`;
