import React from "react";
import { Link } from "gatsby";
import SuccessCard from "./SuccessCard";

const SuccessNext = ({ posts }) => {
  return (
    <aside className="success-more bg-light section pt-5">
      <h2 className="text-center my-lg-5">See how others use Databowl</h2>
      <div className="container-fluid negative-margin ">
        <div className="row contained-xl justify-content-center mt-5">
          <div className="col-lg-10 py-lg-5 pb-5 ">
            <div className="row contained-xl negative-margin_inner">
              {posts.map(({ node: post }, index) => (
                <div key={post.id} className="col-md-6 col-lg-4 mb-4">
                  <SuccessCard
                    title={post.frontmatter.title}
                    excerpt={post.frontmatter.excerpt}
                    slug={post.fields.slug}
                    image={post.frontmatter.featuredimage}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row justify-content-center pb-5">
          <Link
            cover={`true`}
            direction="right"
            bg="#1F1A3C"
            to="/success/"
            className="btn mb-5 btn-outline-primary"
          >
            More Success Stories
          </Link>
        </div>
      </div>
    </aside>
  );
};

export default SuccessNext;
